import './js/refs';
import './js/localStorage';
import './js/lang_menu';
import './js/burgerMenu';
import './js/submit';
import './js/overlay';
import './js/scroll_to_top_btn';

// import './firebase/fb_config';
// import './firebase/fb_auth';
// import './firebase/fb_cloudStore';
// import './firebase/db';

// import './js/content-pages';
// import './js/apiService';
// import './js/renderServies';
// import './js/fetchAndRender';
// import './js/routing';
// import './js/searchByQuery';
// import './js/pagination';
// import './js/genres';
